<template>
	<v-app>
		<v-data-table
			dense
			:headers="headers_bill"
			:items="products"
			class="elevation-1"
			:loading="loading"
			loading-text="Cargando..."
			hide-default-footer
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<div class="pa-4">
						<v-btn color="primary" class="mb-2 rounded-pill" @click="queryServices">
							<v-icon>mdi-plus</v-icon>
							Servicio
						</v-btn>
					</div>
					
					<v-btn color="primary" class="mb-2 rounded-xl" @click="queryProducts">
						<v-icon>mdi-plus</v-icon>
						Producto
					</v-btn>
					<v-btn
						class="ma-2"
						color="green"
						text
						@click="pushProduct"
					>
						Agregar
						<v-icon>mdi-debug-step-into</v-icon>
					</v-btn>
				</v-toolbar>
			</template>
			<template v-slot:body.prepend>
				<tr v-show="isEnable">
					<td>
						<v-text-field 
							v-model="valueItem"
							class='centered-input'
							readonly	
						></v-text-field>
					</td>
					<td>
						<v-text-field
							v-model="typePS"
							class='centered-input'
							readonly							
						></v-text-field>
					</td>
					<td>
						<v-autocomplete
							v-model="model"
							:items="itemsPS"
							item-text="name"
							clearable
							return-object
						>							
						</v-autocomplete>
					</td>
					<td>
						<v-text-field
							v-model="editedItem.itemBill.cat_description"
							class='centered-input'
							readonly
						></v-text-field>
					</td>
					<td>
						<v-autocomplete
							v-model="editedItem.employee"
							:items="itemsEmployees"
							item-text="name"
							clearable
							return-object
						>							
						</v-autocomplete>
					</td>
					<td>
						<v-text-field
							v-model="quantity"
							class='centered-input'
						></v-text-field>
					</td>
					<td>
						<v-text-field
							v-model="editedItem.commission"
							class='centered-input'
						></v-text-field>
					</td>
					<td>
						<v-text-field
							v-model="valueCom"
							class='centered-input'
							readonly
						></v-text-field>
					</td>
					<td>
						<v-text-field
							v-model="editedItem.itemBill.sale_price"
							class='centered-input'
						></v-text-field>
					</td>
					<td>
						<v-text-field
							v-model="points"
							class='centered-input'
							readonly
						></v-text-field>
					</td>
				</tr>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-icon color="danger" small @click="deleteItem(item)">mdi-delete</v-icon>
			</template>
			<template v-slot:no-data>
				Ingrese items
			</template>
			<template v-slot:footer>
				<v-row dense>
				<div class="mt-6 mr-2 text-end"><strong>Total</strong></div>
					<v-col cols="2" class="ms-auto">
						<v-text-field 
							v-model="totalCom"
							readonly
							label="Comision"
						></v-text-field> 
					</v-col>
					<v-col cols="2">
						<v-text-field 
							v-model="subtotalBill"
							readonly
							label="Venta"
						></v-text-field> 
					</v-col>
				</v-row>	
				<v-row dense>
					<div class="mt-6 mr-2 text-end"><strong>Puntos Canje</strong></div>
					<v-col cols="2" class="ms-auto">
						<v-text-field 
							v-model="pointsExchange"
							readonly
						></v-text-field> 
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="2" class="mr=10 ms-auto">
					<div class="mt-6 mr-2 text-end"><strong>Total a pagar</strong></div>
						<v-text-field 
							v-model="totalBill"
							readonly
						></v-text-field> 
					</v-col>
				</v-row>			
			</template>
		</v-data-table>	
		<v-row class="mt-1">
			<v-col cols="12" class="text-end">
				<v-btn
					class="ma-2 rounded-pill"
					tile
					color="success"
					@click="saveBill"
					:disabled="saving"
				>
				<v-icon left>
					mdi-checkbox-marked-circle-outline
				</v-icon>
					Guardar
				</v-btn>
				<v-btn
					tile
					color="red"
					class="ma-2 mr-4 rounded-pill"
					@click="cancel"
				>
				<v-icon left>
					mdi-cancel
				</v-icon>
					Cancelar
				</v-btn>
			</v-col>
		</v-row>
	</v-app>
</template>

<script>

import { ApiInv  } from "../inventory/ApiInv";
import { ApiPay  } from "./ApiPay";

export default {
	name:"ItemsBill",
	props:[
		"client",
		"pointsExc",
		"methodPay"
	],
	data() {
		return{
			api: new ApiInv(),
			apiPay: new ApiPay(),
			loading:false,
			saving:false,
			isEnable:false,
			valueItem:1,
			model:null,
			itemsPS:[],
			typePS:"",
			products:[],
			productsSave:[],
			items:[],
			itemsEmployees:[],
			totalCom:0,
			subtotalBill:0,
			sumPoints:0,
			quantity:1,
			sale_price:0,
			enterprise: 0,
			headers_bill:[
				{ text: "Item", value: "item", sortable: false },
				{ text: "Tipo", value: "typeItem", sortable: false },
        {
          text: "Producto/Servicio",
          align: "start",
          sortable: false,
          value: "service",
        },
        {
          text: "Categoria",
          align: "start",
          sortable: false,
          value: "category",
        },
        {text:'Prestador',value:'employee',sortable:false},
        {text:'Cantidad',value:'quantity',sortable:false},
        {text:'%/$ Comisión',value:'commission',sortable:false},
        {text:'Valor Comisión', value: "valueCommission", sortable:false},
        {text:'Valor',value:'sale_price',sortable:false},
        {text:'Puntos Ganados', value:'points', sortable:false},
				{text:'Acciones',value:'actions',sortable:false},
      ],
			editedItem:{
				id:-1,
				item:1,
				itemBill:{'sale_price':0},	
				employee:{},
				commission:0,			
			},
			defaultItem:{
				id:-1,
				item:1,
				itemBill:{'sale_price':0},	
				employee:{},
				commission:0,	
			},
			rules:{
        in_req: [val => (val || '') > 0 || 'Requerido'],
      },
		}
	},
	computed:{
		valueCom (){
			return this.typePS === 'Servicio' ? Math.ceil(this.editedItem.itemBill.sale_price * (this.editedItem.commission / 100)) : this.editedItem.commission
		},
		points(){
			const price = this.enterprise == 2 ? 20000 : 10000 
			if (this.editedItem.itemBill.sale_price >= price){
				return this.editedItem.itemBill.quantity
			}
			return 0
		},
		totalBill (){
			const valuePoints = this.enterprise == 2 ? 2000 : 1000 
			return this.subtotalBill - (this.pointsExchange*valuePoints)
		},
		pointsExchange (){
			return this.pointsExc
		},
	},
	watch: {
		quantity(val){
			if(val != null && val != 0){
				this.editedItem["itemBill"]["quantity"] = val
				this.editedItem["itemBill"]["sale_price"] *= val
			}else{
				this.editedItem["itemBill"]["sale_price"] = this.sale_price
				this.editedItem["itemBill"]["quantity"] = 1
			}
		},
		model (val) {
			if (val != null){
				this.editedItem['itemBill'] = Object.assign({},val)
				this.editedItem['itemBill']["quantity"] = this.quantity
				this.sale_price = this.editedItem['itemBill']["sale_price"]
				if (this.typePS === 'Servicio'){
					this.editedItem["commission"] = val["commission_percent"]
				}
				else{
					this.editedItem["commission"] = 0
				}
			}else{
				this.editedItem = Object.assign({}, this.defaultItem)
			}
		},
	},
	methods: {
		async iniciar(){
			try {
				this.loading = true;
				this.enterprise = localStorage.getItem("SucursalId")
				let params=`enterprise=${this.enterprise}`
				let rp = await this.api.getProductosP(params);
				let rs = await this.api.getServiciosP(params);
				let re = await this.apiPay.getEmpleados();
				this.itemsProducts = rp
				this.itemsServices = rs
				this.itemsEmployees = re
			} catch (error) {
				alert(error);
				// this.mensaje(error,"Error","error")
				// this.msgError(error)
				this.$swal("Error", error.toString())
			} finally {
				this.loading = false;
			}
		},
		queryServices(){
			this.isEnable=true
			this.typePS = 'Servicio'
			this.itemsPS = this.itemsServices
		},
		queryProducts(){
			this.isEnable=true
			this.typePS = 'Producto'
			this.itemsPS = this.itemsProducts
		},
		pushProduct(){
			let obj = {}
			let objsave = {}
			obj["item"] = this.valueItem
			objsave["item"] = this.valueItem
			objsave["id"] = this.editedItem["id"]
			obj["typeItem"] = this.typePS
			if (this.typePS==='Producto'){
				objsave["product"] = this.editedItem["itemBill"]["id"]
				objsave["comission_pay"] = this.editedItem["itemBill"]["sale_price"] - this.editedItem["itemBill"]["buy_price"]
			}else{
				objsave["service"] = this.editedItem["itemBill"]["id"]
				objsave["employee"] = this.editedItem["employee"]["id"]
				objsave["comission_pay"] = this.valueCom
			}
			
			obj["employee"] = this.editedItem["employee"]["name"]
			obj["service"] = this.editedItem["itemBill"]["name"]
			obj["category"] = this.editedItem["itemBill"]["cat_description"]
			obj["commission"] = this.editedItem["commission"]
			obj["quantity"] = this.editedItem["itemBill"]["quantity"]
			objsave["quantity"] = this.editedItem["itemBill"]["quantity"]
			obj["valueCommission"] = this.valueCom
			obj["sale_price"] = this.editedItem["itemBill"]["sale_price"]
			objsave["service_price"] = this.editedItem["itemBill"]["sale_price"] 
			obj["points"] = this.points
			this.sumPoints += this.points 
			this.products.push(obj)
			this.productsSave.push(objsave)
			this.isEnable=false
			this.model=null
			this.valueItem += 1
			this.totalCom += parseInt(this.valueCom)
			this.quantity = 1
			this.subtotalBill += parseInt(this.editedItem["itemBill"]["sale_price"])
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
			});
		},
		async saveBill(){

			let bill = {}

			bill["id"] = this.editedItem["id"]
			bill["client"] = this.client["id"]
			bill["pay_method"] = this.methodPay["id"]
			bill["items"] = this.productsSave
			bill["total"] = this.totalBill
			bill["points_exchange"] = this.pointsExchange
			bill["enterprise"]=localStorage.getItem("SucursalId")			
			bill["points_awarded"] = this.subtotalBill >= 35000 ? this.sumPoints + 1 : this.sumPoints

			try {
				this.loading = true
				this.saving = true
				let r = await this.apiPay.saveFacturas(bill)
				if (!r.ok){
					if ("items" in r){
						this.$swal("Error",Object.values(r["items"]["0"]).toString(),"error")
					}else{
						this.$swal("Error", Object.values(r)[0][0],"error")
					}
				}else{
					this.$swal("Guardada","Factura Guardada Satisfactoriamente", "success")
					this.$emit("restart")
				}
				// this.mensaje("Guardado Satisfactoriamente")
			} catch (error) {
				alert(error)
				// this.mensaje(error,"Error","error")
			} finally{
				this.cancel()
				this.saving = false
				this.loading = false
			}
				
			},
		cancel(){
			this.products = []
			this.productsSave = []
			this.totalCom = 0
			this.subtotalBill = 0
			this.valueItem = 1
			this.quantity = 1
			this.sumPoints=0
			this.iniciar()
		},
		deleteItem(item){
			let i = this.products.indexOf(item)
			this.subtotalBill -= item["sale_price"]
			this.sumPoints -= item["points"]
			this.totalCom -= item["valueCommission"]
			this.products.splice(i,1)
			this.productsSave.splice(i,1)
			},
		},
	created() {
		this.iniciar();
	}
}
</script>

<style scoped>

	.centered-input >>> input {
		text-align: center
	}
</style>