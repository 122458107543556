<template>
	<v-app>
		<v-form>
			<v-container>
				<v-toolbar flat color="white">
					<v-toolbar-title>Cliente</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<v-autocomplete 
						v-model="model"
						:loading="loading"
						:items="items"
						:filter="customFilter"
						hint="Identificación"
						persistent-hint
						hide-no-data
						item-text="identification"	
						label="Buscar"
						class="mx-4"
						:search-input.sync="search"
						clearable
						return-object
					>
						<template v-slot:selection="{ attr, on, item, selected }">
							<v-chip
								v-bind="attr"
								:input-value="selected"
								color="blue-grey"
								class="white--text"
								v-on="on"
							>
								<span v-text="item.identification"></span>
							</v-chip>
						</template>
					</v-autocomplete>
					<v-autocomplete 
						v-model="modelMethodsPay"
						:loading="loading"
						:items="itemsMethodsPay"
						hint="Metodo de pago"
						persistent-hint
						hide-no-data
						item-text="name"	
						label="Buscar"
						class="mx-4"
						clearable
						return-object
					>
					</v-autocomplete>
					<v-spacer></v-spacer>
					<v-btn color="warning" dark class="mb-2" @click="iniciar">
						<v-icon>cached</v-icon>
					</v-btn>
					<v-btn color="primary" dark class="mb-2" @click="addClient=true">
						<v-icon>add_box</v-icon>
					</v-btn>
				</v-toolbar>
				<v-row>
					<v-col
						cols="12"
						md="4"	
					>
						<v-text-field
							v-model="editedItem.name"
							label="Nombre"
							:readonly="!isEditingName"
						>
							<template v-slot:append-outer>
								<v-slide-x-reverse-transition
									mode="out-in"
								>
									<v-icon
										:key="`icon-${isEditingName}`"
										:color="isEditingName ? 'success' : 'info'"
										@click="isEditingName = !isEditingName"
										v-text="isEditingName ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
									></v-icon>
								</v-slide-x-reverse-transition>
							</template>
						</v-text-field>
					</v-col>
					<v-col
						cols="12"
						md="4"
					>
						<v-text-field
							v-model="editedItem.email"
							label="E-mail"
							:readonly="!isEditingEmail"
						>
							<template v-slot:append-outer>
									<v-slide-x-reverse-transition
										mode="out-in"
									>
										<v-icon
											:key="`icon-${isEditingEmail}`"
											:color="isEditingEmail ? 'success' : 'info'"
											@click="isEditingEmail = !isEditingEmail"
											v-text="isEditingEmail ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
										></v-icon>
									</v-slide-x-reverse-transition>
								</template>
							</v-text-field>
					</v-col>
					<v-col
						cols="12"
						md="4"
					>
						<v-text-field
							v-model="editedItem.address"
							label="Dirección"
							:readonly="!isEditingAddress"
						>
							<template v-slot:append-outer>
								<v-slide-x-reverse-transition
									mode="out-in"
								>
									<v-icon
										:key="`icon-${isEditingAddress}`"
										:color="isEditingAddress ? 'success' : 'info'"
										@click="isEditingAddress = !isEditingAddress"
										v-text="isEditingAddress ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
									></v-icon>
								</v-slide-x-reverse-transition>
							</template>
						</v-text-field>
					</v-col>
					<v-col
						cols="12"
						md="4"
					>
						<v-text-field
							v-model="editedItem.phone"
							label="Celular"
							:readonly="!isEditingPhone"
						>
							<template v-slot:append-outer>
								<v-slide-x-reverse-transition
									mode="out-in"
								>
									<v-icon
										:key="`icon-${isEditingPhone}`"
										:color="isEditingPhone ? 'success' : 'info'"
										@click="isEditingPhone = !isEditingPhone"
										v-text="isEditingPhone ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
									></v-icon>
								</v-slide-x-reverse-transition>
							</template>
						</v-text-field>
					</v-col>
					<v-col
						cols="2"
					>
						<v-text-field
							v-model="editedItem.points"
							label="Puntos"
							readonly
						>
						</v-text-field>
						</v-col>
						<v-col
							cols="2"
						>
							<v-btn 
								color="primary" 
								dark 
								class="mt-3"
								@click.stop="pointsSelect=true"
							>
								Canjear
							</v-btn>
						</v-col>
					<v-col
						cols="12"
						md="4"
					>
						<v-checkbox
							v-model="editedItem.advertising"
							label="Publicidad"
							:readonly="!isEditingAddress"
						>
						</v-checkbox>
					</v-col>
				</v-row>
			</v-container>
		</v-form> 
		<v-dialog
      v-model="pointsSelect"
      max-width="290"
			persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Puntos a canjear
        </v-card-title>

        <v-card-text>
					<v-text-field
						v-model="pointsExc"
						label="Puntos"
						:rules="rules.in_req"
					>
					</v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="cancelExc"
            >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="acceptExc"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		<ItemsBill 
			:client="editedItem"  
			:pointsExc="points" 
			:methodPay="modelMethodsPay"
			@restart="iniciar"
			/>
		<div v-if="addClient">
			<NewClient 
				:dialog="addClient" 
				:editedIdex="editedIdex" 
				:editItem="editedItem" 
				@cerrarDesdeHijo="closeModal"
				/>
		</div>
	</v-app>
</template>

<script>

import { ApiPay  } from "./ApiPay";
import NewClient from "./NewClient";
import ItemsBill from "./ItemsBill";

export default {
	name: 'Fact',
	components: {
		NewClient,
		ItemsBill,
	},	
	data() {
		return{
			loading: false,
			items: [],
			itemsMethodsPay: [],
			editedIdex:-1,
			search: null,
			model: null,
			modelMethodsPay: null,
			addClient:false,
			isEditingName: false,
			isEditingEmail: false,
			isEditingAddress: false,
			isEditingPhone: false,
			isEditingAdv: false,
			select: null,
			api: new ApiPay(),
			pointsSelect:false,
			enterprise: 0,
			pointsExc:0,
			points:0,
			editedItem:{
				id:-1,
				type_identification:{text:"CEDULA", value:"CC"},
				identification:"",
				name:"",
				address:"",
				email:"",
				phone:"",
				genre:{text:"Masculino", value:"M"},
				points:0,
				advertising:false,
      },
			defaultItem:{
				id:-1,
				type_identification:{text:"CEDULA", value:"CC"},
				identification:"",
				name:"",
				address:"",
				email:"",
				phone:"",
				genre:{text:"Masculino", value:"M"},
				points:0,
				advertising:false,
      },
			rules:{
				in_req: [
					val => (val || '') <= this.editedItem.points || 'Puntos insuficientes',
					val => ((val || '') <= (this.enterprise == 2 ? 5 : 10))  || 'Supera el límite permitido',
				],
			},
		}
	},
	watch: {
		model (val) {
			if (val != null){
				this.editedItem = Object.assign({},val)
			}else{
				this.editedItem = Object.assign({}, this.defaultItem)
			}
		},
		search () {
			if (this.items.length > 0) return
			this.loading = true;
			this.querySelections()
		},	
		pointsSelect(val){
			val || this.cancelExc()
		}
	},	
	methods: {
		async iniciar(){
			this.model=null
			this.enterprise = localStorage.getItem("SucursalId")
			this.items = []
			this.pointsExc=0
			this.points=0
			let params=`enterprise=${this.enterprise}`
			let rM = await this.api.getMetodosPagoP(params);
			this.itemsMethodsPay = rM
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
            });
		},
		async querySelections(){
			try {
				this.loading = true;
				let params=`enterprise=${localStorage.getItem("SucursalId")}`
				let r = await this.api.getClientesP(params);
				this.items = r
			} catch (error) {
				alert(error);
				// this.mensaje(error,"Error","error")
				// this.msgError(error)
				this.$swal("Error", error.toString())
			} finally {
				this.loading = false;
			}
		},
		// async services(){
			
		// },
		customFilter (item, queryText) {
			const textOne = item.identification
			const searchText = queryText
			return textOne.indexOf(searchText) > -1 
		},		
		closeModal(){
			this.addClient = false
            this.editedIdex = -1
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
			});
			this.iniciar()
		},
		acceptExc(){
			if(this.pointsExc <= this.editedItem.points){
				this.points = this.pointsExc
				this.pointsSelect = false
			}
		},
		cancelExc(){
			this.pointsExc=0
			this.pointsSelect = false
		},
	},
	created(){
		this.iniciar()
	},
}
</script>

<style scoped>

</style>
